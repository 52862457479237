import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
      if (navigator.serviceWorker && process.env.RAILS_ENV === 'production') {
        navigator.serviceWorker.register('/service-worker.js', { scope: './' })
          .then(function(reg) {
            console.log('[Companion]', 'Service worker registered!');
            console.log(reg);
          })
          ;
      } else {
        console.debug('Service worker not installed');
      }
  }

}
