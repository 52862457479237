import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['addItem', 'template', 'fields']

  connect() {
    if (this.fieldsTargets.length < 1) {
      this.addAssociation()
    }
  }

  addAssociation(event) {
    const content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf());
    this.addItemTarget.insertAdjacentHTML('beforebegin', content)
  }
}
