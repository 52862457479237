import { Controller } from "stimulus";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import XHRUpload from "@uppy/xhr-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

export default class extends Controller {
  static targets = [ "trigger", "form", "input", "directUploadUrl", "previewList" ]

  connect() {
    this._setupUppy();
  }

  trigger(e) {
    e.preventDefault();
    this._setupUppy();
  }

  _setupUppy = () => {
    console.log('setup');

    this.triggerTarget.addEventListener('click', (e) => e.preventDefault());

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    let uppy = Uppy({
      autoProceed: true,
      allowMultipleUploads: true,
      closeModalOnClickOutside: true,
      closeAfterFinish: true,
      proudlyDisplayPoweredByUppy: false,
      logger: Uppy.debugLogger,
      restrictions: {
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png' ]
      }
    })
    .use(XHRUpload, {
      endpoint: `/transactions/${this.data.get("transactionId")}/images`,
      bundle: false,
      headers: { "X-CSRF-Token": csrfToken }
    })
    .use( Dashboard, {
      trigger: this.triggerTarget
    })
    .on('file-added', (file) => {
      console.log('Added file', file)
    })
    .on('complete', (result) => {
      console.log('result', result);
      // element. querySelectorAll('[data-pending-upload]').forEach( element => element.parentNode.removeChild(element));
      result.successful.forEach( file => {
        console.log('file', file)
        this.previewListTarget.innerHTML += `
          <img
            width="200"
            height="200"
            data-behavior="uppy-preview"
            src="${file.response.body.uploadURL}"
          >
        `;
      })
    });
  }
}
