import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal, Card, CardText, CardBody, CardTitle } from 'reactstrap';

import ShareIcon from './shareIcon';
import NklhIcon from './nklh_icon.png';
import useIosInstallPrompt from './useIosInstallPrompt';
import useWebInstallPrompt from './useWebInstallPrompt';

// https://blog.wick.technology/pwa-install-prompt/

function InstallPWA() {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }
  return (
    <Modal isOpen centered>
      <Card>
        <img
          className="mx-auto"
          style={{
            borderTopRightRadius: '50%',
            borderTopLeftRadius: '50%',
            backgroundColor: '#fff',
            marginTop: '-50px'
          }}
          width="100px"
          src={NklhIcon}
          alt="Icon"
        />
        <CardBody>
          <CardTitle className="text-center">
            <h3>Installeer App</h3>
          </CardTitle>
          {iosInstallPrompt && (
            <>
              <CardText className="text-center">
                Klik
                <img
                  src={ShareIcon}
                  style={{ margin: 'auto 8px 8px' }}
                  className=""
                  alt="Add to homescreen"
                  width="20"
                />
                en dan &quot;Add to Home Screen&quot;
              </CardText>
              <div className="d-flex justify-content-center">
                <Button onClick={handleIOSInstallDeclined}>Maak Toe</Button>
              </div>
            </>
          )}
          {webInstallPrompt && (
            <div className="d-flex justify-content-around">
              <Button color="primary" onClick={handleWebInstallAccepted}>
              Installeer
              </Button>
              <Button onClick={handleWebInstallDeclined}>Maak Toe</Button>
            </div>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
};

export default InstallPWA;

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <InstallPWA />,
    document.body.appendChild(document.createElement('div')),
  )
})
