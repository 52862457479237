import { Controller } from "stimulus";
import Rails from "@rails/ujs";

// https://documentation.onesignal.com/v5.0/docs/web-push-sdk#section-is-push-notifications-supported
export default class extends Controller {

  connect() {
    console.log('push');
    this._subscribeNotification();
    if (!("Notification" in window)) {
      console.error("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      console.log("Permission to receive notifications has been granted");
    } else if (Notification.permission !== 'denied') {
        Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
          if (permission === "granted") {
            console.log("Permission to receive notifications has been granted");
        };
      });
    }
  }


  _subscribeNotification = () => {
    window.OneSignal = window.OneSignal || [];
    const appId = this.data.get("appId");
    let currentUserToken = this.data.get('uuid');
    OneSignal.push(() => {
      if (Array.isArray(window.OneSignal)) {
        OneSignal.init({
          appId: appId,
          notifyButton: {
            enable: false,
          },
          allowLocalhostAsSecureOrigin: true,
        });
      }
      var isPushSupported = OneSignal.isPushNotificationsSupported();
      var isPushNotificationsEnabled = OneSignal.isPushNotificationsEnabled();
      console.log("Push ID: isPushSupported", isPushSupported);
      // OneSignal.getUserId().then((userId) => {
      //   console.log("Push ID:", userId);
      //   if (userId && (currentUserToken !== userId)) {
      //     let formData = new FormData();
      //     formData.append('user[one_signal_uuid]', userId);
      //     formData.append('user[push_supported]', isPushSupported);
      //     Rails.ajax({
      //       type: "patch",
      //       url: this.data.get('url'),
      //       data: formData
      //     })
      //   }
      // });
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        console.log("The user's subscription state is now:", isSubscribed);
      });
    });
  }

}

// https://fcm.googleapis.com/fcm/send/c-IlbgnP9o4:APA91bFfhDs-KCX9dsHTsD-DQIYoFQ9XEmoDosuPsV9r5VGUqqwAvHhYLmgXJsgyG-V6cPuKG-vRd0-56sclOtFnpRptdYNYlYIWqHMTf8tdKQVy8zyvIgboZYZhXDP04tGGrLNy2NUm
