import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['destroyField', 'fieldsGroup']

  removeAssociation(event) {
    this.destroyFieldTarget.value = 1
    this.fieldsGroupTarget.classList.add("d-none")
  }
}
